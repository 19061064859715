import { useQuery } from '@apollo/client';
import SpinnerContainer from 'common/components/SpinnerContainer';
import { QueryItemsResponse, QueryName } from 'common/models/graphql';
import { REFRESH_INTERVALS, MonitoredAmm, MONITORED_AMMS } from 'constants/constants';
import { listMonitorVaultsQuery } from 'graphql/queries/listMonitorVaults.query';
import React, { useState } from 'react';
import MonitorVaultsTable from 'common/components/MonitorVaultsTable/MonitorVaultsTable';
import { AppSyncFarm, MonitorVaults } from 'common/models/monitorVaults';
import { ContentTableWrapper } from './app.style';
import { listFarmsQuery } from 'graphql/queries/listFarms.query';
import MonitorVaultsTableFilters from 'common/components/MonitorVaultsTable/MonitorVaultsTableFilters';
import { RebalancingStrategies } from 'common/models/strategies';
import { listStrategiesQuery } from 'graphql/queries/listStrategies.query';
import { StyledFlexRow } from 'common/styles/common.styles';
import { ChainId } from '@ichidao/ichi-sdk/dist/src/crypto/networks';

type Props = {
  onlyNeedRebalance: boolean;
};
export enum LiquidityAmountInVaults {
  NoLiquidity = 0,
  OnlyWithLiquidity = 1,
  Both = 2,
}

const MonitorVaultsContainer: React.FC<Props> = (props) => {
  const [tab, setTab] = useState<MonitoredAmm>(MONITORED_AMMS[0].amms[0]);
  const [criterion, setCriterion] = useState<string>('tvl');
  const [liquidityFilter, setLiquidityFilter] = 
    useState<LiquidityAmountInVaults>(LiquidityAmountInVaults.OnlyWithLiquidity);
  const {
    loading: loadingFarms,
    error: errorLoadingFarms,
    data: dataFarms,
    networkStatus: networStatusFarms,
    refetch: refetchFarms
  } = useQuery<QueryItemsResponse<AppSyncFarm>>(listFarmsQuery, {
    fetchPolicy: 'cache-and-network',
    pollInterval: REFRESH_INTERVALS[QueryName.listFarms]
  });
  const { loading, error, data } = useQuery<QueryItemsResponse<MonitorVaults>>(listMonitorVaultsQuery, {
    fetchPolicy: 'cache-and-network',
    pollInterval: REFRESH_INTERVALS[QueryName.listMonitorVaults]
  });
  const {
    loading: loadingStrategies,
    error: errorLoading,
    data: dataStrategies,
    networkStatus: networStatus,
    refetch: refetch
  } = useQuery<QueryItemsResponse<RebalancingStrategies>>(listStrategiesQuery, {
    fetchPolicy: 'cache-and-network',
    pollInterval: REFRESH_INTERVALS[QueryName.listStrategies]
  });

  const strategies: RebalancingStrategies[] = dataStrategies?.listStrategies?.items || [];

  const vaults: MonitorVaults[] = props.onlyNeedRebalance
    ? data?.listMonitorVaults?.items.filter((v) => v.needRebalance) || []
    : data?.listMonitorVaults?.items || [];

  const farms: AppSyncFarm[] = dataFarms?.listFarms.items || [];

  const chainId = tab.chain;

  const table =  {
    'headers': [
      {
        'name': 'Name',
        'sortBy': 'displayname'
      },
      {
        'name': 'DTR',
        'helpText': '',
        'sortBy': 'deposittokenratio'
      },
      {
        'name': 'Price',
        'helpText': ''
      },
      {
        'name': '*',
        'helpText': ''
      },
      {
        'name': 'Base Range',
        'helpText': ''
      },
      {
        'name': 'Limit Range',
        'helpText': ''
      },
      {
        'name': 'Pend. Dep.',
        'helpText': '',
        'sortBy': 'pendingdepositratio'
      },
      {
        'name': 'Last Rebal.',
        'helpText': '',
        'sortBy': 'lastrebalance'
      },
      {
        'name': chainId === ChainId.Celo ? 'Comb. Ratio' : 'LP price',
        'helpText': '',
      },
    ]
  };

  if (error) {
    console.error(error);
  }

  return (
    <SpinnerContainer isLoading={loading}>
      <MonitorVaultsTableFilters 
        handleClick={(amm: MonitoredAmm) => setTab(amm)} 
        currTab={tab} 
        handleSorting={(sortBy: string) => setCriterion(sortBy)}
        handleLiquidityAmount={(liqAmnt: LiquidityAmountInVaults) => setLiquidityFilter(liqAmnt)}
        currLiquidityAmountFilter={liquidityFilter}
      />
      <ContentTableWrapper>
        <MonitorVaultsTable 
          table={table} 
          vaults={vaults} 
          farms={farms} 
          strategies={strategies}
          filter={tab} 
          liquidityFilter={liquidityFilter}
          handleSorting={(sortBy: string) => setCriterion(sortBy)}
          criterion={criterion} />
      </ContentTableWrapper>
      <StyledFlexRow gap='12px'>
        <span>H - healthy</span>
        <span>D - defensive</span>
        <span>U - under-inventory</span>
        <span>O - over-inventory</span>
      </StyledFlexRow>
      <StyledFlexRow gap='12px'>
        <span>H(with red background) - tick is outside the concentrated position</span>
      </StyledFlexRow>
    </SpinnerContainer>
  );
};

export default MonitorVaultsContainer;
