/* eslint-disable operator-linebreak */
import { StyledFlexRow } from 'common/styles/common.styles';
import React from 'react';
import Button from '../Button';
import ChainSelector from '../ChainSelector';
import { MonitoredAmm } from 'constants/constants';
import { LiquidityAmountInVaults } from 'containers/MonitorVaultsContainer';

type Props = {
  handleClick: (btn: MonitoredAmm) => void;
  currTab: MonitoredAmm;
  handleSorting: (btn: string) => void;
  handleLiquidityAmount: (btn: LiquidityAmountInVaults) => void;
  currLiquidityAmountFilter: LiquidityAmountInVaults;
};

const MonitorVaultsTableFilters: React.FC<Props> = (props) => {
  const tab = props.currTab;
  const currLiquidityAmountFilter = props.currLiquidityAmountFilter;
  const btnWithLiquidityClassName = currLiquidityAmountFilter === 1 ? 'primary-button-bg-color m-05 p-1' : 'primary-button-disabled-bg-color m-05 p-1';
  const btnNoLiquidityClassName = currLiquidityAmountFilter === 0 ? 'primary-button-bg-color m-05 p-1' : 'primary-button-disabled-bg-color m-05 p-1';
  const btnAllLiquidityClassName = currLiquidityAmountFilter === 2 ? 'primary-button-bg-color m-05 p-1' : 'primary-button-disabled-bg-color m-05 p-1';

  return (
    <StyledFlexRow justifyContent='space-between' alignItems='start'>
      <ChainSelector handleClick={props.handleClick} currAmm={props.currTab as MonitoredAmm} />
      <StyledFlexRow justifyContent='right' gap='5px'>
        <Button 
          onClick={() => props.handleLiquidityAmount(1)} 
          title='With Liquidity' 
          className={btnWithLiquidityClassName} />
        <Button 
          onClick={() => props.handleLiquidityAmount(0)} 
          title='No Liquidity' 
          className={btnNoLiquidityClassName} />
        <Button 
          onClick={() => props.handleLiquidityAmount(2)} 
          title='All' 
          className={btnAllLiquidityClassName} />
        <div 
          className="cursor-pointer underline primary-color" 
          onClick={ () => props.handleSorting('tvl') } 
          title="tvl" >
          Sort by TVL
        </div>
      </StyledFlexRow>
    </StyledFlexRow>
  );
};

export default MonitorVaultsTableFilters;
