/* eslint-disable react/jsx-wrap-multilines */
/* eslint-disable operator-linebreak */
import { AppSyncFarmToken, MonitorVaults } from 'common/models/monitorVaults';
import React from 'react';
import { StyledMonitorVaultsSubTextOne } from '../MonitorStablePoolsTable/monitorStablePoolsTable.style';

type Props = {
  vault: MonitorVaults;
  isShown: boolean;
  token: Nullable<AppSyncFarmToken>;
};

const SupportIrrList: React.FC<Props> = (props) => {
  const arrIrrAmounts = props.vault.supportIrrTokenAmount;
  const baseTokenName = props.token?.displayName ?? '';
  const isIchiVault = props.vault.name.includes('ichi');
  const boostTokenName = isIchiVault ? 'ICHI' : baseTokenName;
  if (props.isShown && arrIrrAmounts) {
    return (
      <>
        {arrIrrAmounts.length > 0 && (
          <div className='primary-text-color'>Amount of {boostTokenName} to boost IRR level to</div>
        )}
        {arrIrrAmounts.map((record) => (
          <StyledMonitorVaultsSubTextOne className="sub-text-color" key={`${props.vault.name}_${record.irrPercent}`}>
            {record.irrPercent}%: {record.amount}<br />
          </StyledMonitorVaultsSubTextOne>
        ))}
      </>
    );
  // eslint-disable-next-line no-else-return
  } else {
    return (<></>);
  }
};

export default SupportIrrList;
