/* eslint-disable react/jsx-wrap-multilines */
/* eslint-disable operator-linebreak */
import { AppSyncFarm, MonitorVaults } from 'common/models/monitorVaults';
import { StyledTableBodyTr, StyledTd } from 'common/styles/common.styles';
import React from 'react';
import FormatFloat from '../FormatPercent/FormatFloat';

type Props = {
  vault: MonitorVaults;
  farms: AppSyncFarm[];
  vaults: MonitorVaults[];
};

const PairedVaultsBalance: React.FC<Props> = (props) => {
  const vault = props.vault;
  const farm = props.farms.filter((f) => f.lpAddress.toLowerCase() === vault.address.toLowerCase())[0];
  const isInverted = farm.tokens[1].isBaseToken;
  const token = isInverted ? farm.tokens[1] : farm.tokens[0];
  const tokenPrice = token.price;
  const pairedToken = isInverted ? farm.tokens[0] : farm.tokens[1];
  const pairedTokenPrice = pairedToken.price;
  const twinFarms = props.farms.filter((f) => 
    f.tokens[0].address.toLowerCase() === farm.tokens[0].address.toLowerCase()
    && f.tokens[1].address.toLowerCase() === farm.tokens[1].address.toLowerCase()
    && f.tokens[0].isBaseToken === farm.tokens[1].isBaseToken);
  let twinFarm: AppSyncFarm | null = null;
  if (twinFarms.length > 0) {
    twinFarm = twinFarms[0];
  } else {
    return (<></>);
  }
  const twinVault = props.vaults.filter((v) => v.address.toLowerCase() === twinFarm.lpAddress.toLowerCase())[0];
  const tokenValue = vault.baseTokenValue * tokenPrice + twinVault?.scarceTokenValue * pairedTokenPrice;
  const pairedTokenValue = vault.scarceTokenValue * tokenPrice + twinVault?.baseTokenValue * pairedTokenPrice;
  const ratio = tokenValue / (tokenValue + pairedTokenValue) * 100;

  return (
    <StyledTableBodyTr key={`${vault.name}100`} className="table-no-border-bottom">
      <StyledTd colSpan={2}>
        Paired Vaults Combined:
      </StyledTd>
      <StyledTd colSpan={1}>
        {token?.displayName}: 
        <FormatFloat 
          value={tokenValue} 
          prefix=' $' 
          displayDecimals={0}
        />
      </StyledTd>
      <StyledTd colSpan={2}>
        {pairedToken?.displayName}:
        <FormatFloat 
          value={pairedTokenValue} 
          prefix=' $' 
          displayDecimals={0}
        />
      </StyledTd>
      <StyledTd colSpan={4}>
        Ratio:
        <FormatFloat 
          value={ratio} 
          prefix=' ' 
          postfix='% ' 
          displayDecimals={0}
        /> / 
        <FormatFloat 
          value={100 - ratio} 
          prefix=' ' 
          postfix='%' 
          displayDecimals={0}
        />
      </StyledTd>
    </StyledTableBodyTr>
  );
};

export default PairedVaultsBalance;
