/* eslint-disable react/jsx-wrap-multilines */
/* eslint-disable operator-linebreak */
import Image from 'common/components/Image';
import { Table, TableHeader } from 'common/models/table';
import { AppSyncFarm, MonitorVaults } from 'common/models/monitorVaults';
import { StyledFlexAlignCenter, StyledTable, StyledTableHeadingTr, StyledTableTh } from 'common/styles/common.styles';
import React from 'react';
import Tooltip from '../Tooltip/Tooltip';
import { TooltipWrapper } from '../Tooltip/tooltip.style';
import MonitorVaultsTablePosition from './MonitorVaultsTablePosition';
import { FarmUtils } from 'utils/farmUtils';
import { MonitoredAmm } from 'constants/constants';
import { LiquidityAmountInVaults } from 'containers/MonitorVaultsContainer';
import { RebalancingStrategies } from 'common/models/strategies';

type Props = {
  table: Table;
  vaults: MonitorVaults[];
  farms: AppSyncFarm[];
  strategies: RebalancingStrategies[];
  filter: MonitoredAmm;
  liquidityFilter: LiquidityAmountInVaults;
  handleSorting: (sortBy: string) => void;
  criterion: string;
};

const MonitorVaultsTable: React.FC<Props> = (props) => {
  const farms = props.farms;
  const strategies = props.strategies;
  const getFarmByVault = (vault: MonitorVaults) =>{
    return farms.filter((f) => f.lpAddress.toLowerCase() === vault.address.toLowerCase())[0];
  }; 
  const getStrategyByVaultAddress = function (vaultAddress: string): RebalancingStrategies | undefined {
    return strategies.filter((s) => s.vaultAddress.toLowerCase() === vaultAddress.toLowerCase())[0];
  }; 
  const filterCriteria = (v: MonitorVaults) => {
    const farm = getFarmByVault(v);
    if (!farm){
      return false;
    }
    const indicator = props.filter.indicator;
    const ammCriteria = indicator === '' 
      ? ((!v.displayName?.replace('(legacy)', '').includes('(') && !v.displayName?.replace('(legacy)', '').includes(')'))
        || v.displayName?.includes('(ethereum-uniswap v3)'))
      : v.displayName?.includes(props.filter.indicator) || v.displayName?.includes(props.filter.name.toLowerCase());
    let liqCriteria = true;
    if (props.liquidityFilter === LiquidityAmountInVaults.OnlyWithLiquidity){
      liqCriteria = v.tvl * FarmUtils.getBaseTokenPrice(farm) >= 100;
    }
    if (props.liquidityFilter === LiquidityAmountInVaults.NoLiquidity){
      liqCriteria = v.tvl * FarmUtils.getBaseTokenPrice(farm) < 100;
    }
    return liqCriteria && ammCriteria && farm.chainId === props.filter.chain;
  };
  const compareVaults = (a: MonitorVaults, b: MonitorVaults) => {
    const farmA = getFarmByVault(a);
    const farmB = getFarmByVault(b);
    let criteria = +b.needRebalance - +a.needRebalance;
    if (props.criterion === 'lastrebalance'){ criteria = Number(b.lastRebalance) * 1000 - Number(a.lastRebalance) * 1000; }
    if (props.criterion === 'deposittokenratio'){ criteria = b.memberTokenRatio - a.memberTokenRatio; }
    if (props.criterion === 'pendingdepositratio'){ criteria = b.pendingDepositsRatio > a.pendingDepositsRatio ? 1 : -1; }
    if (props.criterion === 'displayname'){ criteria = (b.displayName.toLowerCase() > a.displayName.toLowerCase()) ? -1 : 1; }
    if (props.criterion === 'tvl'){ criteria = b.tvl * FarmUtils.getBaseTokenPrice(farmB) - a.tvl * FarmUtils.getBaseTokenPrice(farmA); }
    return criteria;
  };
  const vaults = [...props.vaults]?.filter((v) => {return filterCriteria(v);})
    .sort((a, b) => compareVaults(a, b));

  const getHeaderElem = (header: TableHeader) => {
    return header.sortBy ? (
      <div 
        className="mr-5 cursor-pointer underline" 
        onClick={() => {props.handleSorting(header.sortBy ?? '');}}>
        {header.name}
      </div>
    ) : (
      <div className="mr-5">{header.name}</div>
    );
  };


  return (
    <>
      <StyledTable>
        <thead>
          <StyledTableHeadingTr className="table-border-bottom">
            {props.table?.headers?.map((header) => (
              <StyledTableTh key={header.name} className="sub-text-color">
                <StyledFlexAlignCenter>
                  {getHeaderElem(header)}
                  {header.helpText && (
                    <TooltipWrapper>
                      <Tooltip message={header.helpText} position="top" bubbleSize={{ width: '200px' }}>
                        <Image className="icon-help" alt={header.helpText} />
                      </Tooltip>
                    </TooltipWrapper>
                  )}
                </StyledFlexAlignCenter>
              </StyledTableTh>
            ))}
          </StyledTableHeadingTr>
        </thead>
        <tbody>
          {vaults?.map((vault) => (
            <MonitorVaultsTablePosition 
              key={vault.name} 
              vault={vault}
              token={FarmUtils.getBaseToken(getFarmByVault(vault))}
              farms={farms}
              vaults={vaults}
              chainId={props.filter.chain}
              vaultTokenDecimals={ [getFarmByVault(vault).tokens[0].decimals,
                getFarmByVault(vault).tokens[1].decimals]
              }
              depositTokenPriceUsdc={FarmUtils.getBaseTokenPrice(getFarmByVault(vault))}
              lpPrice={getFarmByVault(vault).lpPrice}
              strategy={getStrategyByVaultAddress(vault.address)}
            />
          ))}
        </tbody>
      </StyledTable>
    </>
  );
};

export default MonitorVaultsTable;
